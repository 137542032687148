{
  "all": "Visi",
  "all_flats": "Visi dzīvokļi",
  "apply": "Piesakies",
  "avilable": "Pieejams",
  "avilable_only": "Pieejams tikai",
  "card_cookies": "Izmantojot šo vietni, jūs piekrītat lietošanas noteikumiem",
  "compass": {
    "n": "Z",
    "s": "D",
    "w": "R",
    "e": "A"
  },
  "description": "Mūsdienīgs biznesa klases dzīvojamais komplekss",
  "download": "Lejupielādēt",
  "duplex": "Divlīmeņu",
  "error": "Kaut kas nogāja greizi... ",
  "finish": {
    "base": "Rupja",
    "white": "Baltā",
    "full": "Pilnīga"
  },
  "status": {
    "free": "Izpārdošana",
    "rent": "Noma",
    "reserved": "Rezervēts",
    "sold": "Pārdots"
  },
  "level": "Līmenis ",
  "next": "Tālāk",
  "not_found": "Dzīvokļi nav atrasti! !",
  "pc": "gab.",
  "per_page": "Rādīt pēc:",
  "price_note": "Cena ir atkarīga no stāvu skaita un dzīvokļa aprīkojuma",
  "requestMsg": "Jūsu pieteikums ir veiksmīgi nosūtīts! Mēs drīz ar jums sazināsimies.",
  "requestPrice": "Pieprasiet",
  "reset": "Noņemt visu",
  "reserved": "Rezervēts",
  "sold": "Pārdots",
  "rent": "Noma",
  "sale": "Izpārdošana",
  "sorting": "Kārtošana:",
  "agreement": ["Es piekrītu", "personas datu", "apstrādes noteikumiem"],
  "callme": {
    "title": ["Pasūtīt", "zvanu"],
    "submit": "Pasūtīt zvanu",
    "info": "Atstājiet savu kontaktinformāciju, un mūsu menedžeri tuvākajā laikā ar Jums sazināsies"
  },
  "fields": {
    "email": {
      "label": "E-pasts",
      "ph": "Ievadiet e-pastu"
    },
    "name": {
      "label": "Vārds",
      "ph": "Ievadiet vārdu"
    },
    "parking": "Autostāvvieta",
    "phone": {
      "label": "Tālrunis",
      "ph": "Ievadiet tālruņa numuru"
    },
    "storeroom": "Uzglabāšanas telpas"
  },
  "map": {
    "touch": "Lai p\u0101rvietotu karti, b\u012bdiet to ar diviem pirkstiem",
    "scroll": "Kartes t\u0101lummai\u0146ai izmantojiet ctrl + ritin\u0101\u0161anu",
    "scrollMac": "Lai veiktu kartes t\u0101lummai\u0146u, izmantojiet \u2318 + ritin\u0101\u0161anu"
  },
  "nav": {
    "about": "Par projektu",
    "architecture": "Arhitektūra",
    "design": "Dzīvokļu dizains",
    "improvement": "Labiekārtošana un infrastruktūra",
    "parking": "Autostāvvieta un uzglabāšanas telpas",
    "location": "Atrašanās vieta",
    "developer": "Būvētājs",
    "flats": "Dzīvokļa izvēle",
    "floor": "Stāvs",
    "layout": "Plānojums",
    "contacts": "Kontakti",
    "documentation": "Dokumentācija",
    "cookies": "Cookies",
    "privacy": "Konfidencialitātes politika",
    "creation": "Radīšana un virzīšana",
    "contact_us": "Sazinies ar mums ",
    "process": "Būvniecības process"
  },
  "request": {
    "title": ["Pieteikums", "dzīvoklim"],
    "text": "Atstājiet savu kontaktinformāciju, un mūsu menedžeri tuvākajā laikā ar Jums sazināsies",
    "checks_caption": "Atlasiet \"Parkošanās vieta\" vai \"Uzglabāšanas telpas\", un menedžeris atradīs Jums piemērotu variantu",
    "submit": "Nosūtīt"
  },
  "paginator": {
    "next": "Uz priekšu",
    "back": "Atpakaļ"
  },
  "params": {
    "area": "S, kv",
    "id": "№",
    "finish": "Apdare",
    "flat_number": "Dzīvoklis Nr. ",
    "floor": "Stāvs",
    "rooms": "Istabas",
    "rooms_qty": "Istabu skaits",
    "price": "Cena, €*",
    "price2": "Cena, tūkst. eiro",
    "type": "Dzīvokļa veids",
    "with_furniture": "Ar mēbelēm",
    "without_furniture": "Bez mēbelēm",
    "status_caption": "Statusu",
    "status": "Statusu"
  },
  "table_sorting": {
    "floor": "Pēc stāva",
    "id": "Dzīvoklis Nr. ",
    "rooms": "Pēc istabu skaita",
    "area": "Pēc platības",
    "finish": "Pēc apdare",
    "price": "Pēc cenas"
  },
  "validation": {
    "email": "Lauks {field} ir aizpildīts nepareizi",
    "required": "Lauks {field} ir obligāts"
  },
  "pages": {
    "architecture": {
      "title": "Dzīvojamā kompleksa arhitektūra"
    },
    "contacts": {
      "title": "Kontakti",
      "address_caption": "Pārdošanas birojs",
      "contacts_caption": "Kontakti",
      "time_caption": "Darba laiks",
      "time": "Darba dienās"
    },
    "cookies": {
      "title": "Cookies"
    },
    "developer": {
      "title": "Būvētājs"
    },
    "documentation": {
      "title": "Dokumentācija"
    },
    "design": {
      "title": "Dzīvokļu dizains"
    },
    "improvement": {
      "title": "Labiekārtošana \nun infrastruktūra"
    },
    "index": {
      "title": "Panorama Residence",
      "title_0": [
        "Biznesa klases",
        " mūsdienīgs \ndzīvojamais komplekss",
        null
      ],
      "title_1": [null, "Dzīvojamā kompleksa\n", "arhitektūra"],
      "title_2": "Labiekārtošana \nun infrastruktūra",
      "choose": "Izvēlieties dzīvokli",
      "more": "Skatīt vairāk ",
      "from": "No",
      "price": "Cena par kv"
    },
    "flats-flat-id": {
      "title": "Dzīvoklis",
      "title_about": "Par dzīvokli",
      "title_plan": "Stāva plānojums",
      "back": "Atpakaļ",
      "share": "Dalīties",
      "btn_request": "Atstāt pieteikumu",
      "btn_download": "Lejupielādēt"
    },
    "flats": {
      "title": "Dzīvokļa atlase",
      "sub_title": ["Atlase pēc parametriem", "Atlase pēc plānojuma"],
      "filter": "Filtrs",
      "floors": "Stāvi",
      "layouts": "Plānojumi",
      "table_link": "Skatīt"
    },
    "location": {
      "title": "Atrašanās vieta"
    },
    "parking": {
      "title": "Autostāvvieta un uzglabāšanas telpas"
    },
    "process": {
      "title": "Būvniecības process"
    },
    "privacy": {
      "title": "Konfidencialitātes politika"
    }
  },
  "page_404": {
    "title": "Lapa nav atrasta ",
    "btn": "Izvēlieties dzīvokli"
  },
  "design": "Dzīvokļu dizains",
  "close": "Aizveriet"
}
